<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('pages.warehouse.warehouseListing')" :onSearch="onSearch">
                <template v-slot:headerLeft>
                    <button type="button" name="add-warehouseListing" class="btn btn-success" @click="onAddNew">
                        {{ $t('menu.addWarehouseImport') }}
                    </button>
                </template>

                <template v-slot:searchBox>
                    <CForm>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.startDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.startDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.products')}} </label>
                                    <div class="col-sm-9">                                  
                                        <multiselect :options="products"
                                                     v-model="searchInfo.selectedProducts"
                                                     :loading="isLoadingProducts"
                                                     :searchable="true"
                                                     :close-on-select="true"
                                                     :clear-on-select="false"
                                                     :multiple="true"
                                                     :placeholder="$t('common.typetosearch')"
                                                     :noOptionsText="$t('common.theListIsEmpty')"
                                                     :reset-after="false"
                                                     :local-search="false"
                                                     @search-change="asyncFind"
                                                     :internal-search="false"
                                                     id="ajax"
                                                     label="name"
                                                     track-by="id">
                                        </multiselect>
                                    </div>
                                </div>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="5">
                                <div role="group" class="form-group form-row">
                                    <label class="col-form-label col-sm-3">{{$t('common.endDate')}} </label>
                                    <div class="col-sm-9">
                                        <date-picker v-model="searchInfo.endDate" format="DD/MM/YYYY"></date-picker>
                                    </div>
                                </div>
                            </CCol>
                            <CCol md="7">
                                <CInput v-model="searchInfo.shipmentCode" horizontal>
                                    <template #label>
                                        <div class="col-form-label col-sm-3 col-form-label">
                                            {{ $t('pages.warehouse.shipmentCode') }}                                            
                                        </div>
                                    </template>
                                </CInput>
                            </CCol>
                        </CRow>
                    </CForm>
                </template>
                <template v-slot:list>
                    <div class="row">
                        <div class="col-md-2">
                            <b><label>Tổng số tiền:</label></b>
                        </div>
                        <div class="col-md-8">
                            <p><b>{{$func.formatCurrency(totalAmount)}}</b></p>
                        </div>
                    </div>
                    <CDataTable :items="allWareHouses"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                @page-change="onPageChanged"
                                :loading="isLoading"
                                :items-per-page="itemsPerPage">
                        <template #price_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.price)" />
                            </td>
                        </template>
                        <template #quantity_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.quantity)" />
                            </td>
                        </template>
                        <template #amount_text="{item}">
                            <td class="py-2">
                                <label v-text="$func.formatNumber(item.amount)" />
                            </td>
                        </template>
                        <template #packing_text="{item}">
                            <td class="py-2">
                                <label v-text="showPackagingSpecification(item.unitName)" />
                            </td>
                        </template>
                        <template #createdDate_text="{item}">
                            <td class="py-2">
                                <label v-text="formatDate(item.createdDate)" />
                            </td>
                        </template>
                        <template #warehouse_actions="{item, index}">
                            <td class="py-2">
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onEditWareHouse(item, index)">
                                    {{ $t('common.edit') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                    <CPagination :pages="totalPages"
                                 :activePage="pageIndex + 1"
                                 @update:activePage="onPageChanged"></CPagination>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>

<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import ListForm from '@/components/ListForm.vue'

    import { mapState, mapActions } from 'vuex'
    export default ({
        name: 'WareHouseList',
        data() {
            return {
                products: [],
                isLoadingProducts: false,
                itemsPerPage: 10,
                warningModal: false,
                warningModalMessage: "",
                fields: [
                    { key: 'productName', label: i18n.t('pages.product.productName') },
                    { key: 'price_text', label: i18n.t('common.price') },
                    { key: 'quantity_text', label: i18n.t('common.quantity') },
                    //{ key: 'unitName', label: i18n.t('common.unit') },
                    //{ key: 'unitName', label: i18n.t('pages.product.packaging') },
                    { key: 'amount_text', label: i18n.t('common.amount') },
                    { key: 'packing_text', label: i18n.t('pages.product.packaging') },
                    { key: 'shipmentCode', label: i18n.t('pages.warehouse.shipmentCode') },
                    { key: 'createdDate_text', label: i18n.t('pages.warehouse.createdDate') },
                    { key: 'warehouse_actions', label: i18n.t('common.action'), _style: 'width:12%', sorter: false, filter: false }
                ]
            };
        },
        components: {
            ListForm,
            Confirmation
        },
        computed: {           
            ...mapState('warehouse', ['searchInfo', 'isLoading', 'allWareHouses', 'totalPages', 'pageIndex', 'totalAmount']),
        },
        methods: {
            ...mapActions('warehouse', ['searchWareHouses']),

            formatDate(date) {             
                if (date === undefined)
                    return '';
                return this.$moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
            },
            showPackagingSpecification(unitName) {
                if (!unitName)
                    return '';
                return unitName;               
            },
             onEditWareHouse(item) {
                 this.$router.push('/warehouses/edit/' + item.id);
            },
            onSearch() {
                this.searchWareHouses();
            },
            onPageChanged(pageNo, reduced) {
                console.log('PageChanged', { pageNo, reduced });
                this.searchWareHouses(pageNo);
            },
            async asyncFind(query, id) {
                console.log("LENGTH", query.length);
                if (query.length >= 3) {
                    this.isLoadingProducts = true;
                    var result = await this.$productService.searchByName(query);
                    this.isLoadingProducts = false;
                    this.products = result;
                }
                console.log("Search", { query, id });
            },
            onAddNew() {
                this.$router.push('/warehouses/add');
            }
        },       
        created() {
            this.searchWareHouses();
        }
    })
</script>